export default {
buildTime: "2024-11-22T21:44:32Z",
commit: "23190443e7da78591674cf05937b46964ef8109f",
npmInfo:
{
  mashlib: '1.10.2',
  npm: '8.19.4',
  node: '18.20.5',
  acorn: '8.13.0',
  ada: '2.8.0',
  ares: '1.29.0',
  base64: '0.5.2',
  brotli: '1.1.0',
  cjs_module_lexer: '1.2.2',
  cldr: '44.1',
  icu: '74.2',
  llhttp: '6.1.1',
  modules: '108',
  napi: '9',
  nghttp2: '1.61.0',
  nghttp3: '0.7.0',
  ngtcp2: '1.3.0',
  openssl: '3.0.15+quic',
  simdutf: '5.6.0',
  tz: '2024a',
  undici: '5.28.4',
  unicode: '15.1',
  uv: '1.44.2',
  uvwasi: '0.0.19',
  v8: '10.2.154.26-node.37',
  zlib: '1.3.0.1-motley'
}
};
